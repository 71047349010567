<template>
  <jf-layout>
    <template slot="head">
      <el-button size="small" type="primary" icon="el-icon-circle-plus" v-if="isAuth('Slideshow:tbslideshow:save')" @click="openAdd">新增</el-button>
      <div class="hint">
        <div>已选中<span class="c-warning">{{selectArr.length}}</span>条</div>
        <div>
          <el-button size="mini" type="danger" icon="el-icon-delete" v-if="isAuth('Slideshow:tbslideshow:delete')" :disabled="selectArr.length<=0" @click="deleteItem()">删除</el-button>
        </div>
      </div>
    </template>
    <el-table slot="body" v-loading="tableLoading" border :data="list" ref="multipleTable" @selection-change="getSelect">
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" prop="title" label="名称" />
      <el-table-column align="center" prop="picPath" label="图片">
        <template slot-scope="scope">
          <el-image style="width: 50px; height: 50px" :src="scope.row.picPath" :preview-src-list="[scope.row.picPath]"></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createDate" label="时间" />
      <el-table-column align="center" prop="orderBy" label="排序" />
	   <el-table-column align="center" prop="organizationType" label="组织类型">
		   <template slot-scope="{row}">
		   			  {{row.organizationType==0?'社区':row.organizationType==1?'企业':''}}
		   </template>
	   </el-table-column>
	  <el-table-column align="center" prop="companyName" label="所属公司"  width="260"/>
	  <el-table-column align="center" prop="companyName" label="只显示本公司轮播图" width="200">
		  <template slot-scope="{row}">
			  {{row.binding==0?'否':'是'}}
		  </template>
	  </el-table-column>
      <el-table-column align="center" prop="realName" label="操作人" />
      <el-table-column align="center" label="操作">
        <template slot-scope="{row}">
          <el-button size="mini" icon="el-icon-edit" v-if="isAuth('Slideshow:tbslideshow:update')" @click="openAdd(row)">编辑</el-button>
          <el-button size="mini" icon="el-icon-delete" v-if="isAuth('Slideshow:tbslideshow:delete')" @click="deleteItem(row)" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <jf-page slot="foot" @change="getList()" :total="total" :page.sync="pageParam.page" :limit.sync="pageParam.limit" />
    <Add :show.sync="show" :id="activeId" />
  </jf-layout>
</template>

<script>
import Add from "./add";
export default {
  components: {
    Add,
  },
  data() {
    return {
      show: false,
      activeId: null,
      total: 0,
      pageParam: {
        page: 1,
        limit: 10,
      },
      list: [],
      tableLoading: false,
      selectArr: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    async getList() {
      this.tableLoading = true;
      let res = await this.$get(
        "/platform-config/slideshow/page",
        this.pageParam
      );
      if (res && res.code == 0) {
        this.list = res.data.list;
        this.total = res.data.total;
        this.tableLoading = false;
      }
    },
    // 选择table
    getSelect(e) {
      this.selectArr = e;
    },
    // 打开新增/修改弹窗
    openAdd(row) {
      this.show = true;
      this.activeId = row ? row.id : "-1";
    },
    // 删除
    deleteItem(row) {
      let ids = [];
      if (row) {
        ids = [row.id];
      } else {
        this.selectArr.map((v) => {
          ids.push(v.id);
        });
      }
      this.$confirm("确定删除此数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.tableLoading = true;
          let res = await this.$del("/platform-config/slideshow", ids);
          if (res && res.code == 0) {
            this.$message.success("删除成功！");
            this.getList();
          }
          this.tableLoading = false;
        })
        .catch(() => {});
    },
  },
  watch: {
    show(n, o) {
      if (!n) {
        this.getList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.hint {
  display: flex;
  justify-content: space-between;
  background: #fff4e3;
  border-radius: 5px;
  padding: 0 17px;
  margin-top: 15px;
}
</style>
