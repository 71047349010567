<template>
	<el-dialog v-dialogDrag title="新增轮播图" @open="open" @open.once="initEditor" :visible.sync="visible" width="1000px"
		@close="close">
		<el-form label-width="150px" :model="param" v-loading="fromLoading" ref="form" :rules="rules">
			<el-form-item label="标题" prop="title">
				<el-input class="w300" v-model="param.title" maxlength="15"></el-input>
			</el-form-item>
			<el-form-item label="组织类型" prop="organizationType" >
				<el-select v-model="param.organizationType" placeholder="请选择" clearable @change="getCompanyArr">
					<el-option label="社区" :value="0"></el-option>
					<el-option label="企业" :value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="所属公司" v-if="param.organizationType||param.organizationType===0">
				<el-select v-model="param.tenantCodeList" multiple filterable placeholder="请选择" clearable
					style="width: 400px;">
					<el-option v-for="(item,i) in companyArr" :key="i" :label="item.companyName"
						:value="item.tenantCode"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="只显示本公司轮播图" v-if="param.tenantCodeList&&param.tenantCodeList.length>0">
				<el-switch v-model="param.binding" :active-value="1" :inactive-value="0" active-color="#13ce66"
					inactive-color="#f4f4f4">
				</el-switch>
			</el-form-item>
			<el-form-item label="跳转类型" prop="clickType">
				<el-select v-model="param.clickType" placeholder="请选择" clearable>
					<el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="跳转地址" prop="clickDetail" v-if="param.clickType==1||param.clickType==2">
				<el-input v-model="param.clickDetail" placeholder="请输入跳转地址"></el-input>
			</el-form-item>
			<el-form-item label="文本" prop="clickDetail" v-if="param.clickType==3">
				<el-input v-model="param.clickDetail" placeholder="请输入文本" maxlength="50" show-word-limit clearable>
				</el-input>
			</el-form-item>
			<el-form-item label="图片" prop="picPath">
				<jf-ossUpload-img :value="param.picPath" :contraction="false" @result="result"></jf-ossUpload-img>
			</el-form-item>
			<el-form-item label="内容">
				<div v-show="!fromLoading" id="editor" class="editor"></div>
			</el-form-item>
			<el-form-item label="排序" prop="orderBy">
				<el-input-number v-model="param.orderBy" :min="1"></el-input-number>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="close">取 消</el-button>
			<el-button size="small" type="primary" :loading="btnLoading" @click="submit">确 定</el-button>
		</span>
	</el-dialog>
</template>
<script>
	import E from "wangeditor";
	import {
		wangEditorUpload
	} from "@/utils/oss"
	export default {
		components: {},
		data() {
			return {
				editor: null, //富文本对象
				btnLoading: false,
				fromLoading: false,
				param: {
					picPath: "",
					orderBy: 1,
				},
				companyArr: [],
				typeArr: [{
						label: "无",
						value: "0"
					}, {
						label: "外部链接",
						value: "1"
					},
					{
						label: "内部链接",
						value: "2"
					},
					{
						label: "文本",
						value: "3"
					},
				],
				rules: {
					title: [{
							required: true,
							message: "请输入标题",
							trigger: "blur",
						},
						{
							min: 3,
							max: 15,
							message: "长度在 3 到 15 个字符",
							trigger: "blur",
						},
					],
					clickDetail: [{
						required: true,
						message: "请输入",
						trigger: "change",
					}],
					organizationType: [{
						required: true,
						message: "请选择",
						trigger: "change",
					}],
					orderBy: [{
						required: true,
						message: "请输入标题",
						trigger: "change",
					}, ],
					picPath: [{
						required: true,
						message: "请上传图片",
						trigger: "change",
					}, ],
					content: [{
						required: true,
						message: "请输入内容",
						trigger: "change",
					}, ],
				},
			};
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			id: {
				type: String,
			},
		},
		computed: {
			visible: {
				get() {
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		watch: {
			id(n, o) {
				if (n && n != -1) {
					this.getDetail();
				} else {
					this.param = {
						picPath: "",
						orderBy: 1,
					};
					if (this.editor) {
						this.editor.txt.html("");
					}
					this.$forceUpdate()
				}
			}
		},
		created() {},
		mounted() {},
		methods: {
			open() {
				if (this.id) {
					this.getDetail();
				} else {
					this.param = {
						picPath: "",
						orderBy: 1,
					};
					if (this.editor) {
						this.editor.txt.html("");
					}
				}
			},
			close() {
				this.param = {
					picPath: "",
					orderBy: 1,
				};
				this.$emit('update:show', false)
			},
			// 获取公司列表
			getCompanyArr(e) {
				let param={
					organizationType:e
				}
				this.$get('/platform-admin/tenant/list',param).then(res => {
					if (res && res.code == 0) {
						this.companyArr = res.data
					}
				})
			},
			result(val) {
				this.param.picPath = val
			},
			initEditor() {
				this.$nextTick(() => {
					this.editor = new E("#editor");
					this.editor.config.customUploadImg = wangEditorUpload;
					this.editor.create();
				});
			},
			// 获取上传成功图片 url
			getImg(e) {
				this.param.picPath = e;
			},
			// 获取详情
			async getDetail() {
				this.fromLoading = true;
				let res = await this.$get(`/platform-config/slideshow/${this.id}`);
				if (res && res.code == 0) {
					this.param = res.data;
					if(this.param.organizationType||this.param.organizationType===0){
						this.getCompanyArr(this.param.organizationType)
					}
					if (this.editor) this.editor.txt.html(this.param.content);
				}
				this.fromLoading = false;
			},
			// 新增
			async save() {
				let res = await this.$post("/platform-config/slideshow", this.param);
				this.btnLoading = false;
				if (res && res.code == 0) {
					this.$message.success("新增成功！");
					this.$emit("update:show", false);
				}
			},
			// 修改
			async upDate() {
				let res = await this.$put("/platform-config/slideshow", this.param);
				if (res && res.code == 0) {
					this.btnLoading = false;
					this.$message.success("修改成功！");
					this.$emit("update:show", false);
				}
			},
			// 点击提交
			submit() {
				this.param.content = this.editor.txt.html();
				this.$refs.form.validate((valid) => {
					if (valid) {
						if(!this.param.tenantCodeList||this.param.tenantCodeList.length<=0){
							this.param.binding=0
						}
						this.btnLoading = true;
						if (this.id) {
							this.upDate();
						} else {
							this.save();
						}
					}
				});
			},
		},
	};
</script>

<style scoped>
	.editor {
		width: 100%;
		height: 350px;
	}

	>>>.w-e-text-container {
		height: 250px !important;
	}

	>>>.el-loading-spinner {
		z-index: 100;
	}
</style>
